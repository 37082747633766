export const Heroku = {
    name: "Heroku",
    domain: "herokuapp.com"
};
export const Evennode = {
    name: "Evennode",
    domain: "ap-1.evennode.com"
};
export const InfinityFree = {
    name: "InfinityFree",
    domain: "rf.gd"
};
export const ProFreeHost = {
    name: "ProFreeHost",
    domain: "unaux.com"
};